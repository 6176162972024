import React from "react";
import { IconType } from "react-icons";

export interface DropdownItemInterface {
  label: string;
  onClick?: () => void;
  split?: boolean;
  icon?: IconType;
}

export interface DropdownInterface {
  items: DropdownItemInterface[];
  username?: string;
  dropdownOutsideClickRef: any;
}

const Dropdown: React.FC<DropdownInterface> = ({
  items,
  username,
  dropdownOutsideClickRef,
}) => {
  return (
    <div
      className="absolute right-0 z-50 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md border border-gray-200 bg-white shadow-lg outline-none"
      role="menu"
      ref={dropdownOutsideClickRef}
    >
      {username && (
        <div className="px-4 py-3">
          <p className="text-sm leading-5">Signed in as</p>
          <p className="truncate text-sm font-medium leading-5 text-gray-900">
            {username}
          </p>
        </div>
      )}
      <div className="py-1">
        {items.map((item, index) => (
          <React.Fragment key={index}>
            {item.split && <div className="border-t border-gray-300"></div>}
            <button
              className="flex w-full items-center justify-start border-r-4 border-transparent px-4 py-2 text-left text-sm font-medium leading-5
               text-gray-700 transition duration-200 hover:border-dark-main-base hover:bg-gray-100"
              role="menuitem"
              onClick={item.onClick}
            >
              {item.icon && <item.icon className="mr-3" />}
              {item.label}
            </button>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
