import "./index.css";
import { Outlet, useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";

const Layout = () => {
  const currentPath = useLocation().pathname;
  return (
    <div className="bg-gray layout-container flex h-[100dvh] bg-white duration-500 dark:bg-main-blue-main ">
      <div className="hidden lg:block">
        <Sidebar />
      </div>
      <div className="flex w-full flex-col overflow-auto">
        <div>
          <Navbar />
        </div>
        <div
          id="main-content"
          className="bg-dark-gray flex-grow overflow-y-auto rounded-lb-0 rounded-r-0 lg:rounded-tl-[30px] bg-gray-100 p-2 lg:p-4 dark:bg-main-gray-background"
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
