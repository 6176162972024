import { useEffect, useMemo, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import defineAbilitiesFor, { RolesInterface } from "../../../config/ability";
import { AbilityContext } from "./AbilityContext";
import { onAuthStateChanged } from "firebase/auth";
import { db, initAuth } from "clients/firebase";
import { doc, getDoc } from "firebase/firestore";
const PrivateRoutes = () => {
  const location = useLocation(); // Get current route information
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const auth = initAuth();
  const navigate = useNavigate();

  // Define public routes
  const publicRoutes = ["/login"];

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      try {
        if (user) {
          // Reference to the user's document
          const userDocRef = doc(db, "users", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);
          
          if (userDocSnapshot.exists()) {
            // Get document data
            const userDocData = userDocSnapshot.data();
            const needsPasswordReset = userDocData?.needsPasswordReset;

            // Redirect to password reset if needed
            if (
              needsPasswordReset === true ||
              needsPasswordReset === undefined
            ) {
              navigate("/first-time-login/reset-password");
            } else if (
              location.pathname === "/first-time-login/reset-password"
            ) {
              navigate("/");
            }
          } else {
            navigate("/first-time-login/reset-password");
          }

          setIsAuthenticated(true);
        } else {
          // User is signed out
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error("Error fetching user document:", error);
      } finally {
        setLoading(false);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [auth, location.pathname]);

  // Assuming you have a mechanism to retrieve the current user's roles, e.g., from local storage
  const currentUserRoles = useMemo(() => {
    // Retrieve user roles from local storage or another persistence mechanism
    const user = JSON.parse(localStorage.getItem("user") || "{}");

    return user.roles || ["guest"]; // Default to "guest" role if no roles are found
  }, []);

  const ability = useMemo(
    () => defineAbilitiesFor(currentUserRoles),
    [currentUserRoles],
  );

  if (loading) {
    return <div>Loading...</div>; // Or any other loading indicator you prefer
  }

  // If the current route is public, allow access without authentication
  if (publicRoutes.includes(location.pathname)) {
    return <Outlet />;
  }

  // If the user is not authenticated and the route is not public, redirect to login
  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // If authenticated, provide the ability context and render the protected routes
  return (
    <AbilityContext.Provider value={ability}>
      <Outlet />
    </AbilityContext.Provider>
  );
};

export default PrivateRoutes;
