import { defineAbility } from "@casl/ability";

export type Actions = "read" | "create" | "update" | "delete" | "manage";
export type RolesInterface = ("admin" | "teacher" | "student" | "guest")[];

export default function defineAbilitiesFor(roles: RolesInterface = ["guest"]) {
  return defineAbility((can, cannot) => {
    if (roles.includes("admin")) {
      can("manage", "all");
      cannot("manage", "PromptsPage");
    } else {
      if (roles.includes("teacher")) {
        // can("read", "all")
        can("manage", [
          "QuestionBanks",
          "QuizGame",
          "SummariesPage",
          "CreateExam",
          "ExamQuestions",
          "CreateExam",
          "ProfilePage",
          "GameCoordinator",
          "GamePlayer",
          "GameLeaderboard",
          "CreateGame",
        ]);
        can("create", "exam");
        can("read", [
          "courseUsersTable",
          "attendanceTable",
          "courseTopics",
          "stats",
          "statistics",
        ]);
      }
      if (roles.includes("student")) {
        can("manage", [
          "ExamTake",
          "ProfilePage",
          "practice",
          "GamePlayer",
          "GameLeaderboard",
        ]);
        cannot("create", "exam");
        cannot("read", [
          "questionsBanksTable",
          "courseUsersTable",
          "attendanceTable",
          "attendance",
          "courseSettings",
        ]);
        cannot("read", ["courseTopics", "stats"]);
        can("create", ["startExam"]);
        can("read", ["statistics"]);
      }
    }
  });
}
