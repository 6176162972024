import React, { useEffect, useState } from "react";
import { BreadcrumbItemProps } from "./index.d";
import BreadcrumbsService from "../../services/Breadcrumbs";
import { useLocation } from "react-router-dom";

const BreadcrumbItem: React.FC<BreadcrumbItemProps> = ({
  text,
  href,
  isLast,
}) => (
  <>
    {isLast ? (
      <span className="cursor-default text-dark-main-base dark:text-light-main-base">
        {text}
      </span>
    ) : (
      <a
        href={href}
        className="text-black hover:text-light-main-base dark:text-white dark:hover:text-dark-main-base"
      >
        {text}
      </a>
    )}
    {!isLast && (
      <span className="lg:mx-5 mx-1 text-gray-500 dark:text-gray-300">/</span>
    )}
  </>
);

const Breadcrumb = () => {
  const [data, setData] = useState<Array<{ text: string; href: string }>>([]);
  const breadcrumbsService = new BreadcrumbsService();
  const location = useLocation();
  const path = window.location.origin;

  useEffect(() => {
    setData([]);
    breadcrumbsService
      .getBreadcrumsData(location.pathname)
      .then((res) => {
        setData(res);
      })
      .catch((err) => console.log("Failed to Load Breadcrumbs"));
    // .finally(() => console.log());
  }, [location.pathname]);
  return (
    <div className="flex items-center overflow-x-auto whitespace-nowrap py-4">
      {data.map((item, index) => {
        return (
          <BreadcrumbItem
            key={index}
            text={item.text}
            href={window.location.origin + "/" + item.href}
            isLast={index === data.length - 1}
          />
        );
      })}
    </div>
  );
};

export default Breadcrumb;
